import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import SEO from '../../../components/seo';
import { Intro } from '../../../components/Intro/Intro';
import { StyledLink } from '../../../components/StyledLink';
import Projects from '../../../components/Projects';
import { Container, CSSGrid, Col } from '../../../components/Grid';
import { CardOutbound, CardContainer, CardBody } from '../../../components/Card';
import { designTokens } from '../../../components/Theme/designTokens';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import ImageIcon from '../../../components/ImageIcon';
import { ButtonOutbound } from '../../../components/Button';
import portfolioPDF from '../../../static/Ryan Parag-Product Designer_Portfolio.pdf';
import { motion } from 'framer-motion';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Intro mdxType="Intro">
    <CSSGrid mdxType="CSSGrid">
      <Col col={7 / 12} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
        <h2 style={{
              marginTop: '0'
            }}>Portfolio Presentation 👨‍🏫</h2>
        <p className="lead">Use one of the following links to view my portfolio in a shorter presentation format.</p>
        <p>Choose from either viewing as a presentation in <strong>Figma</strong>, as a presentation in <strong>Google Slides</strong>, or as a downloadable <strong>PDF</strong>.</p>
      </Col>
      <Col col={5 / 12} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
        <motion.div initial={{
              y: `${designTokens.space[4]}`,
              opacity: 0
            }} animate={{
              y: 0,
              opacity: 1
            }} transition={{
              duration: 0.5
            }}>
          <CardOutbound href="https://www.figma.com/proto/QsG3EM959hIXy7kvoEIh4A/Profile?node-id=17%3A21&viewport=902%2C4342%2C0.25&scaling=min-zoom" target="_blank" mdxType="CardOutbound">
            <CardContainer marginBottom={designTokens.space[3]} mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row-reverse'
                    }}>
                  <div style={{
                        display: 'table',
                        width: designTokens.space[6],
                        height: designTokens.space[6],
                        marginLeft: designTokens.space[3]
                      }}>
                    <ImageIcon srcName="figma-icon" mdxType="ImageIcon" />
                  </div>
                  <div>
                    <Text mdxType="Text"><strong>View Presentation in Figma</strong></Text>
                    <Text size={designTokens.fontSizes[0]} marginTop={designTokens.space[1]} color={({
                          theme
                        }) => theme.grey500} mdxType="Text">
                      Performance may be slow due to animations and transitions
                    </Text>
                  </div>
                </div>
              </CardBody>
            </CardContainer>
          </CardOutbound>
        </motion.div>
        <motion.div initial={{
              y: `${designTokens.space[4]}`,
              opacity: 0
            }} animate={{
              y: 0,
              opacity: 1
            }} transition={{
              duration: 0.5,
              delay: 0.25
            }}>
          <CardOutbound href="https://docs.google.com/presentation/d/1NY8_S1C2YKPIGuCbwlVYiFvmCJvkEtRdLOmDm6U62-c/edit?usp=sharing" target="_blank" mdxType="CardOutbound">
            <CardContainer marginBottom={designTokens.space[3]} mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row-reverse'
                    }}>
                  <div style={{
                        display: 'table',
                        width: designTokens.space[6],
                        height: designTokens.space[6],
                        marginLeft: designTokens.space[3]
                      }}>
                    <ImageIcon srcName="google-slides-icon" mdxType="ImageIcon" />
                  </div>
                  <div>
                    <Text mdxType="Text"><strong>View Presentation in Slides</strong></Text>
                    <Text size={designTokens.fontSizes[0]} marginTop={designTokens.space[1]} color={({
                          theme
                        }) => theme.grey500} mdxType="Text">
                      Some animations and transitions may not be included
                    </Text>
                  </div>
                </div>
              </CardBody>
            </CardContainer>
          </CardOutbound>
        </motion.div>
        <motion.div initial={{
              y: `${designTokens.space[4]}`,
              opacity: 0
            }} animate={{
              y: 0,
              opacity: 1
            }} transition={{
              duration: 0.5,
              delay: 0.5
            }}>
          <CardOutbound href={portfolioPDF} download mdxType="CardOutbound">
            <CardContainer marginBottom={designTokens.space[3]} mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row-reverse'
                    }}>
                  <div style={{
                        display: 'table',
                        width: designTokens.space[6],
                        height: designTokens.space[6],
                        marginLeft: designTokens.space[3]
                      }}>
                    <ImageIcon srcName="pdf-icon" mdxType="ImageIcon" />
                  </div>
                  <div>
                    <Text mdxType="Text"><strong>Download Presentation as PDF</strong></Text>
                    <Text size={designTokens.fontSizes[0]} marginTop={designTokens.space[1]} color={({
                          theme
                        }) => theme.grey500} mdxType="Text">
                      Missing all animations and transitions
                    </Text>
                  </div>
                </div>
              </CardBody>
            </CardContainer>
          </CardOutbound>
        </motion.div>
      </Col>
    </CSSGrid>
  </Intro>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      